body {
  overflow-y: scroll;
  overflow-x: hidden;
  color: #7e8082;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: none;
  padding: 0;
  white-space: nowrap;
  word-wrap: normal;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

a {
  color: #333;
}

a:hover, a:focus {
  color: #97999c;
  text-decoration: none !important;
  -webkit-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
	cursor:pointer;
}

.port-view-more {
  font-weight: 300;
  color: #333;
}

.cis-cont {
  a {
    color: #333;
    &:hover {
      color: #333;
    }
  }
}

.port-item {
  float: none;
  overflow: hidden;
}

.fs-parent {
  cursor: pointer;
  & .open-sub:before {
    top: 50%;
    transform: translate(2px, -50%);
    font-size: 13px;
  }
}

.fs-sub {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition: all 0.4s cubic-bezier(0, 1, 0.5, 1);
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
}

.fs-active .fs-sub {
  opacity: 1;
  max-height: 275px;
  overflow: hidden;
  transition: all 0.4s ease;
}

.fab {
  font-style: normal;
}

#rs-fullscr {
  ul {
    padding: 0;
  }
}

#main-menu .sub {
  padding: 15px 10px 15px 24px;
  a {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
  }

  li {
    font-size: 11px;
  }
}

.google-map-marker {
  background-image: url(/images/map-marker.png);
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover
}

h3.media-heading {
  margin: 0 0 32px 5px;
  font-family: 'Raleway', sans-serif,
}

.members-container {
  &-other {
    padding-top: 80px;
    &__page {
      padding-top: 0;
    }
  }
}

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  &-name-wrapper {
    display: flex;
    align-items: baseline;
  }
  &-link {
    width: 24px;
    height: 24px;
    color: #333;
    &:hover {
      color: #555;
    }
  }
  &-icon {
    font-size: 16px;
  }
  h3 {
    font-size: 15px;
  }

  span {
    font-size: 14px;
    font-style: normal;
    display: block;
    max-width: 250px;
  }
}
.member-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .member-image {
    max-width: 250px;
    position: relative;
    width: 100%;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
    // width: 250px;
    // height: 250px;
    img,
    svg {
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.ministers-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ministry-section {
  padding-top: 50px;
  p {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 400;
  }
}

.donation-section {
  padding-top: 50px;
  padding-bottom: 50px;
  p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    margin: 0 0 5px;
  }

  h3, h4, h5 {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
  }
}

.page-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cis-text {
  &-inner {
    display: flex;
  }
  div {
    margin-right: 7px;
    font-size: 12px;
  }
}

.media-section {
  flex: 1;
}

.media {
  &-item {
    &-title {
      font-family: "Raleway", sans-serif;
      line-height: 20px;
      font-size: 16px;
      font-weight: 500;
      margin: 12px 0 0;
      min-height: 40px;
      overflow: hidden;
    }
  }
}

.footer-copy {
  margin: 0;
}

.footer-2-copy {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 1px;
  color: #848484;
  text-transform: uppercase;
}

.btn-group {
  &__filter {
    width: 100%;
    max-width: 170px;
    display: flex !important;
    .btn-default__main {
      flex-grow: 1;
      text-align: left;
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.social__warpper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .social-label {
    margin: 0 0 10px;
  }
}

.social-label {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 1px;
  color: #848484;
  text-transform: uppercase;
  margin: 32px 0 0px;
}

.ministry-schedule {
  padding-top: 40px;
  padding-bottom: 20px;
}
.about-section {
  h1 {
    font-size: 20px;
    display: inline-block;
    margin: 0;
  }
  padding-top: 50px;
  font-family: 'Raleway', sans-serif;
  h3 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 20px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
  }
}

.main-title-wrapper {
  p {
    display: inline;
  }
}

.section-title span {
  padding-left: 5px;
}

.rs-fullscr-container {
  padding-top: 60px;
}

#rs-fullscr {
  max-height: calc(100vh - 60px) !important;
}

.slider-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  max-height: calc(100vh - 60px);
}

.swiper {
  &-wrapper {
    position: absolute;
    top: 0;
  }
  &-container {
    overflow: hidden;
    height: 0;
    padding-top: 45%;
    position: relative;
  }
  &__wrapper {
    padding: 60px 0 0;
  }
  &__image {
      object-fit: cover;
      width: 100%;
      max-height: calc(100vh - 60px);
  }
}

@media (max-width: 1650px) {
  .swiper {
    &-container {
      padding-top: 50%;
    }
  }
}

.pdf-wrapper {
  margin-bottom: 20px;
  a {
    color: #fff !important;
  }
}

.video-section {
  padding: 70px 0 40px;
  .video_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-player {
    overflow: hidden;
    position: relative;
    width:100%;
    &::after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }
    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.fs-primary-nav {
  a {
    color: #333;
  }
}
.tp-caption.dark-light-60,
.tp-caption.dark-black-60 {
  font-size: 30px !important;
}
.tp-caption {
  &.tp-resizeme.caption-bottom {
    color: #7e8082;
  }
}

.affix.header {
  width: 100%;
}

.affix-fix .logo-2 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.header-side-menu.affix-fix.affix .header-wrapper {
  background: #fff !important;
}

.fs-primary-nav {
  padding: 60px 0;
}

.header {
  z-index: 999;
}

.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.section-title,
.page-title {
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 24px;
}

.section-title {
  padding: 12px 25px;
}

.page-title-small,
.page-title {
  letter-spacing: initial;
}

.port-img-overlay {
  display: flex;
}

// .port-main-img {
//   width: 100%;
//   display: block;
//   overflow: hidden;
//   height: 100%;
//   left: 0;
//   position: absolute;
//   top: 0;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   width: 100%;
//   object-fit: cover;
// }
.port-grid .port-item>a {
  margin: 5px;
}

.local-scroll-cont {
  a:hover {
    text-decoration: none;
  }
}

.media-item-handler {
  position: relative;
}

.ReactModal__Overlay {
  background-color: rgba(11, 11, 11, 0.8) !important;
  z-index: 9999;
}

.modal-content-wrp {
  background-color: #000;
  width: 100%;
  max-width: 1200px;
  max-height: 600px;
  height: calc(56.25vw);
}

.modal-close-btn {
  position: absolute;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  outline: none;
  width: 35px;
  height: 35px;
  z-index: 10000;
}

.modal-close-image {
  width: 100%;
  height: 100%;
}

.bible .fes1-box-icon {
  display: flex;
}

.fes1-box-icon img {
  width: 45px;
}

.lightbox-item:hover .port-btn-cont.lightbox-video {
  font-size: 55px;
  margin-top: -32px !important;
}

.lightbox-video {
  display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.port-play-icon {
  width: 75px;
}

.ReactModal__Content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  max-width: 1200px;
  max-height: 600px;
}

#main-menu .navbar .nav>li>a {
  margin: 0 6px 17px 6px;
  letter-spacing: 1px;
  color: #000 !important;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}

#main-menu .sub a {
  color: #d3d3d3 !important;
  padding-right: 0;
}

#main-menu .parent:hover > .sub {
  left: -14px;
}

#main-menu .parent {
  &__arrow-down {
    position: absolute;
    width: 8px;
    right: -3px;
    top: 26px;
  }
  &__sub {
    margin-right: 10px;
  }
}

.header-side-menu .logo-row {
  width: 100%;
  height: 60px;
}

.nav-button {
  background: transparent;
  display: inline-block;
  padding: .4em 1em;
  color: #333;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 13px;
  &:focus {
    outline: none;
  }
  &-sub {
    text-transform: lowercase;
    font-weight: 500;
  }
}

.swiper-container:hover {
  .swiper-button-prev,
  .swiper-button-next {
    visibility: visible;
  }
}

.swiper-button-prev, .swiper-button-next {
  color: #fff;
  opacity: 0.6;
  visibility: hidden;
  transition: visibility 0.2s ease, opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
  &:after {
    font-size: 25px;
  }
}

.footer-2-soc-a a {
  color: #333;
  font-size: 18px !important;
  padding: 7px 7px !important;

  &:hover {
    text-decoration: none;
    color: #555;
  }
}

.footer-2-text-cont {
  &.phone {
    margin-bottom: 0;
  }
}

.footer-2-copy-cont {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.page-section {
  &.news {
    .port-view-more-cont {
      display: none;
    }
  }
}

.fs-primary-nav-trigger {
  background: transparent;
  padding: 0;
  outline: none;
  &:focus {
    outline: none;
  }
}

@media (max-width: 1280px) {
  .cis-text {
    &-inner {
      display: block;
      }
    }
  .port-play-icon {
    width: 60px;
  }

  .port-item:hover .port-main-img, .lightbox-item:hover .port-main-img {
    -webkit-transform: scale(1) translateZ(0);
    -ms-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
  }
}

@media (max-width: 1199px) {
  .members-container-other {
    padding: 0;
  }
}

@media (max-width: 1024px) {

  .ReactModal__Content {
   padding: 5px !important;
  }
  

  .page-title-cont,
  .page-title-small {
    padding-top: 118px;
  }

  .header {
    position: fixed;
    left: 0;
    transition: background .3s ease, height .4s ease;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .page-title-cont,
  .page-title-small {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .page-title {
    font-size: 20px;
  }

  .donation-section {
    .container {
      text-align: center;
    }
  }

  h3.media-heading {
    margin: 0 0 24px 5px;
  }

  .footer-2-copy-cont {
    flex-direction: column;
  }
  
  .footer-copy {
    order: 2;
    margin-top: 5px;
  }

  .footer-2-copy {
    font-weight: 400;
    letter-spacing: initial;
  }

  .social__warpper .social-label {
    margin: 0 0 5px;
  }

  .swiper-button-prev {
    left: 0;
    &:after {
      font-size: 18px;
    }
  }
  
  .swiper-button-next {
    right: 0;
    &:after {
      font-size: 18px;
    }
  }

  .members-container {
    &-other {
      padding-top: 0;
    }
  }

  .member {
    span {
      font-size: 14px;
      font-style: normal;
      display: block;
      max-width: 200px;
    }
  }

  .member-image-wrapper {
    .member-image {
      max-width: 200px;
    }
  }

  .pdf-wrapper {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .ministers-section {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .ministry-schedule {
    padding-top: 5px;
  }

  .video-section {
    padding: 40px 0;
  }

  .ministry-section {
    padding-top: 24px;
    p {
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .about-section {
    padding-top: 24px;
    h1 {
      font-size: 16px;
    }
    h3 {
      font-size: 16px;
    }
    p {
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .contact-section {
    .contact-form-cont {
      padding: 80px 20px 40px 20px;
    }
  }

  .port-overlay-cont {
    display: none;
  }

  .port-img-overlay:after {
    display: none;
  }
  .slider-bg-white-cap {
    display: none;
  }

  .section-more.right {
    display: none;
  }

  .page-section {
    &.news {
      .port-view-more-cont {
        display: block;
      }
    }
  }

  .tp-caption.center-0-478 {
    font-size: 20px !important;
    left: unset !important;
    right: 20px !important;
    transform: unset !important;
  }

  footer {
    padding-bottom: 20px !important;

    .logo-footer-cont {
      margin-bottom: 35px;
    }

    .widget {
      display: none;
    }

    .main-widget {
      margin-bottom: 40px;

      &.widget {
        display: block;
      }
    }

    .footer-2-soc-a a {
      padding: 7px 7px !important;
      font-size: 20px !important;
    }
  }
}

@media (min-width: 1025px) {
  .header-side-menu {
    .fs-primary-nav-trigger {
      display: none;
    }
  }
}

@media (max-width: 320px) {
  .donation-section {
    p strong {
      display: block;
    }
  }
}
