.form {
   &-field {
    position: relative;
    
    &-error {
        position: absolute;
        bottom: -20px;
        font-size: 12px;
        color: red;
    }
    }
   &-submit-btn {
    &:focus {
     outline: none;
    }
   }
   &-spinner {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
   }
}
