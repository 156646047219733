.pagination {
    &__wrapper {
        display: flex;
        justify-content: center;
    }
    & li:first-child,
    & li:last-child{
        margin: 0;
    }
    .disabled {
        & > a {
            opacity: 0.5;
        }
    }
}
